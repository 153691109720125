define("discourse/plugins/discourse-sort-by-likes/discourse/initializers/sort-by-likes", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SORT_BY_LIKES = _exports.SORT_BY_ACTIVITY = void 0;
  const pluginId = "discourse-sort-by-likes";
  const SORT_BY_ACTIVITY = _exports.SORT_BY_ACTIVITY = "activity";
  const SORT_BY_LIKES = _exports.SORT_BY_LIKES = "likes";
  function initPlugin(api, container) {
    api.modifyClass("model:post-stream", {
      pluginId,
      orderStreamByActivity() {
        this.cancelFilter();
        this.set("filter", SORT_BY_ACTIVITY);
        return this.refreshAndJumpToSecondVisible();
      },
      orderStreamByLikes() {
        this.cancelFilter();
        this.set("filter", SORT_BY_LIKES);
        return this.refreshAndJumpToSecondVisible();
      }
    });
  }
  var _default = _exports.default = {
    name: "sort-by-likes-edits",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.13.0", api => {
        initPlugin(api, container);
      });
    }
  };
});