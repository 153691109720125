define("discourse/plugins/discourse-sort-by-likes/discourse/components/like-sort-toggle", ["exports", "@glimmer/component", "@glimmer/tracking", "discourse/components/d-button", "@ember/object", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _dButton, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LikeSortToggle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "sortedByLikes", [_tracking.tracked], function () {
      return false;
    }))();
    #sortedByLikes = (() => (dt7948.i(this, "sortedByLikes"), void 0))();
    constructor() {
      super(...arguments);
      var tc1 = Discourse.__container__.lookup("controller:topic");
      this.sortedByLikes = tc1.filter == "activity" ? false : true;
    }
    get labelText() {
      return this.sortedByLikes ? "sort_by_likes.toggle_likes" : "sort_by_likes.toggle_activity";
    }
    toggleSort() {
      var tc1 = Discourse.__container__.lookup("controller:topic");
      if (this.sortedByLikes) {
        tc1.model.postStream.orderStreamByActivity().then(() => {
          tc1.updateQueryParams();
        });
      } else {
        tc1.model.postStream.orderStreamByLikes().then(() => {
          tc1.updateQueryParams();
        });
      }
      this.sortedByLikes = !this.sortedByLikes;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleSort", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton @action={{this.toggleSort}} @label={{this.labelText}}/>
      
    */
    {
      "id": "OdYnJhvs",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@action\",\"@label\"],[[30,0,[\"toggleSort\"]],[30,0,[\"labelText\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-sort-by-likes/discourse/components/like-sort-toggle.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LikeSortToggle;
});